import React from "react";
const YellowBar = () => {
  return (
    <div className="yellow-bar normal-pad">
      <h3>
        Nuestros procesos son llevados con sumo cuidado para llegar con los
        mayores niveles de calidad a nuestros clientes
      </h3>
    </div>
  );
};

export default YellowBar;
