import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../styles/header.css";

const Header = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const location = useLocation().pathname;

  //Mobile links
  const [showLinksMobile, setShowLinksMobile] = useState(false);

  //Variables
  let linksMobile;
  let closeX;
  let hamburger;

  if (showLinksMobile) {
    /*links are showing*/
    closeX = (
      <div onClick={() => setShowLinksMobile(false)}>
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 2L2 21"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 2L21 21"
            stroke="white"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    );
    linksMobile = (
      <nav className="mobile">
        <NavLink to="/">Inicio</NavLink>
        <NavLink to="/catalogo">Catálogo</NavLink>
        <NavLink to="/produccion">Producción</NavLink>
        <NavLink to="/puntos-de-venta">Puntos de Venta</NavLink>
        <NavLink to="/contacto">Contacto</NavLink>
      </nav>
    );
  } else {
    /*links are not showing*/
    hamburger = (
      <div onClick={() => setShowLinksMobile(true)}>
        <svg
          width="28"
          height="20"
          viewBox="0 0 28 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 4H26C27.104 4 28 3.104 28 2C28 0.896 27.104 0 26 0H2C0.896 0 0 0.896 0 2C0 3.104 0.896 4 2 4ZM26 8H2C0.896 8 0 8.896 0 10C0 11.104 0.896 12 2 12H26C27.104 12 28 11.104 28 10C28 8.896 27.104 8 26 8ZM26 16H2C0.896 16 0 16.896 0 18C0 19.104 0.896 20 2 20H26C27.104 20 28 19.104 28 18C28 16.896 27.104 16 26 16Z"
            fill="white"
          />
        </svg>
      </div>
    );
  }

  //Use effect
  useEffect(() => {
    setShowLinksMobile(false);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <header>
      <div className="max-w header-wrapper">
        <div className="logo-wrapper">
          <NavLink to="/">
            <img src="/images/logo.png" alt="Logotipo Kansatex" />
          </NavLink>
        </div>
        <nav>
          <NavLink to="/">Inicio</NavLink>
          <NavLink to="/catalogo">Catálogo</NavLink>
          <NavLink to="/produccion">Producción</NavLink>
          <NavLink to="/puntos-de-venta">Puntos de Venta</NavLink>
          <NavLink to="/contacto">Contacto</NavLink>
        </nav>
        <div className="hamburger">
          {hamburger}
          {closeX}
        </div>
      </div>
      {linksMobile}
    </header>
  );
};

export default Header;
