import React from "react";
const Nosotros = () => {
  return (
    <div id="NosotrosPage">
      <h1>Página de Nosotros</h1>
    </div>
  );
};

export default Nosotros;
