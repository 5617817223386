import React from "react";
import "../styles/pages-style/contacto.css";

const Contacto = () => {
  return (
    <div id="contactoPage" className="father">
      <div className="max-w">
        <section id="contacto-wrapper">
          <h1>Contáctanos</h1>
          <a
            className="google-maps-wrap"
            href="https://goo.gl/maps/g6h64vvXZwvbj6dN9"
            target="_blank"
          >
            Ver en Google Maps
            <svg
              width="64"
              height="60"
              viewBox="0 0 60 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_114_137)">
                <circle
                  r="29.1739"
                  transform="matrix(1 0 0 -1 33.1739 29.174)"
                  fill="black"
                  fill-opacity="0.78"
                  shape-rendering="crispEdges"
                />
              </g>
              <path
                d="M29.5911 39.9224L39.8275 29.6859L29.5911 19.4494"
                stroke="white"
                stroke-width="4"
              />
              <defs>
                <filter
                  id="filter0_d_114_137"
                  x="0"
                  y="0"
                  width="66.3478"
                  height="66.3479"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_114_137"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_114_137"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </a>
          <form name="formulario" method="post">
            <input type="hidden" name="form-name" value="formulario" />
            <input placeholder="Nombre*" type="text" name="name" />
            <input placeholder="Correo*" type="email" name="email" />
            <input placeholder="Número telefónico" type="phone" name="phone" />
            <textarea placeholder="Mensaje" name="message"></textarea>
            <button type="submit">Enviar Mensaje</button>
          </form>
        </section>
      </div>
    </div>
  );
};

export default Contacto;
