import React from "react";

const TitleBlack = (props) => {
  const texto = props.titulo;

  return (
    <div className="black-title">
      <h2>{texto}</h2>
    </div>
  );
};

export default TitleBlack;
