import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Inicio from "../pages/Inicio";
import Nosotros from "../pages/Nosotros";
import Catalogo from "../pages/Catalogo";
import Produccion from "../pages/Produccion";
import PuntosDeVenta from "../pages/PuntosDeVenta";
import Contacto from "../pages/Contacto";
import Error404 from "../pages/Error404";

const Main = () => {
  return (
    <main>
      <Routes>
        <Route path="*" element={<Error404 />} />
        <Route path="/" element={<Inicio />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/catalogo" element={<Catalogo />} />
        <Route path="/produccion" element={<Produccion />} />
        <Route path="/puntos-de-venta" element={<PuntosDeVenta />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
    </main>
  );
};

export default Main;
