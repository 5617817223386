import React from "react";
const Error404 = () => {
  return (
    <div id="errorPage">
      <h1>Volver al Inicio</h1>
    </div>
  );
};

export default Error404;
