import React from "react";
import "../styles/pages-style/produccion.css";
import YellowBar from "../components/YellowBar";
import TitleBlack from "../components/TitleBlack";

const Produccion = () => {
  return (
    <div id="produccionPage" className="father">
      <div className="max-w">
        <section
          id="produccion-hero"
          className="flex align-center mobile-column"
        >
          <div className="left-block">
            <TitleBlack titulo="Producción" />
            <div className="normal-pad" id="title-produccion-hero">
              <p className="large">
                Procesos estandarizados para la tintura y acabado de nuestros
                géneros.
              </p>
            </div>
          </div>
          <div className="normal-pad right-block">
            <img
              className="full-row"
              src="/images/produccion/hero.jpg"
              alt="Hilo Imagen Principal"
            />
          </div>
        </section>
        <section className="rows-production">
          <div className="flex justify-center align-center">
            <h2 className="produccion-type">Tintura de hilo</h2>
          </div>
          <div className="normal-pad" id="hilo-wrapper">
            <div className="full-row flex space-between align-center reverse-mobile-column mobile-mb4">
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/hilo1.png"
                  alt="Enconado"
                />
                <svg
                  className="point right"
                  width="291"
                  height="22"
                  viewBox="0 0 291 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 11L291 11" stroke="#0B0B08" stroke-width="4" />
                  <rect x="196" width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">1</p>
                </div>
                <div>
                  <h3 className="mb-1">Enconado</h3>
                  <p>
                    Se define como el cambio de formato del hilado, contenido en
                    conos a bobinas de peso y tamaño uniforme, para la tintura
                    poster.
                  </p>
                </div>
              </div>
            </div>
            <div className="full-row flex space-between align-center mobile-column mobile-mb4">
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">2</p>
                </div>
                <div>
                  <h3 className="mb-1">Teñido y acabado</h3>
                  <p>
                    Proceso por el cual se dota al hilo de color y tacto suave.
                    Las bobinas de hilo son coloreadas y suavizadas, al tono que
                    solicita nuestros clientes.
                  </p>
                </div>
              </div>
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/hilo2.png"
                  alt="Teñido"
                />
                <svg
                  className="point left"
                  width="317"
                  height="22"
                  viewBox="0 0 317 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M26 11L317 11" stroke="#0B0B08" stroke-width="4" />
                  <rect width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
            </div>
            <div className="full-row flex space-between align-center reverse-mobile-column">
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/hilo3.png"
                  alt="Empacado"
                />
                <svg
                  className="point right"
                  width="291"
                  height="22"
                  viewBox="0 0 291 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 11L291 11" stroke="#0B0B08" stroke-width="4" />
                  <rect x="196" width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">3</p>
                </div>
                <div>
                  <h3 className="mb-1">EMPAQUETADO</h3>
                  <p>
                    Las bobinas teñidas y secas, son colocadas en nuestras
                    enconadoras para el cambio de bobina a cono. Pesadas,
                    empacadas y etiquetadas, para la entrega a cliente.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="rows-production">
          <div className="flex space-between align-center mobile-column normal-pad no-pad-bottom ">
            <div className="col5 mobile-mb4">
              <img
                src="/images/produccion/tela.png"
                alt="Tela Imagen Principal"
              />
            </div>
            <div className="col45 center-mobile">
              <h2 className="produccion-type">Tela</h2>
            </div>
          </div>
          <div className="normal-pad" id="hilo-wrapper">
            <div className="full-row flex space-between align-center mobile-column mobile-mb4">
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">1</p>
                </div>
                <div>
                  <h3 className="mb-1">TEJIDO </h3>
                  <p>
                    Nuestra especialidad es el desarrollo de tejido de punto
                    circular. Procedimientos estandarizados. Maquinaria de
                    ultima Generacion. Proveedores certificados.
                  </p>
                </div>
              </div>
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/tela1.png"
                  alt="Tejido"
                />
                <svg
                  className="point left"
                  width="317"
                  height="22"
                  viewBox="0 0 317 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M26 11L317 11" stroke="#0B0B08" stroke-width="4" />
                  <rect width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
            </div>
            <div className="full-row flex space-between align-center reverse-mobile-column mobile-mb4">
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/tela2.png"
                  alt="Revisado"
                />
                <svg
                  className="point right"
                  width="291"
                  height="22"
                  viewBox="0 0 291 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 11L291 11" stroke="#0B0B08" stroke-width="4" />
                  <rect x="196" width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">2</p>
                </div>
                <div>
                  <h3 className="mb-1">Acabado</h3>
                  <p>
                    Teñido de tela por maquinaria tipo Jet. Calidad en el
                    proceso. Buena solidez al lavado. Excelente estabilidad
                    dimencional.
                  </p>
                </div>
              </div>
            </div>
            <div className="full-row flex space-between align-center mobile-column mobile-mb4">
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">3</p>
                </div>
                <div>
                  <h3 className="mb-1">Venta</h3>
                  <p>
                    Empacado con polietileno, para cubrir al genero de polvo y
                    salpicaduras. Etiquetado brinda al cliente la garantia de la
                    tela.
                  </p>
                </div>
              </div>
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/tela3.png"
                  alt="Teñido"
                />
                <svg
                  className="point left"
                  width="317"
                  height="22"
                  viewBox="0 0 317 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M26 11L317 11" stroke="#0B0B08" stroke-width="4" />
                  <rect width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
            </div>
            <div className="full-row flex space-between align-center reverse-mobile-column mobile-mb4">
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/tela4.png"
                  alt="Acabado"
                />
                <svg
                  className="point right"
                  width="291"
                  height="22"
                  viewBox="0 0 291 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 11L291 11" stroke="#0B0B08" stroke-width="4" />
                  <rect x="196" width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">4</p>
                </div>
                <div>
                  <h3 className="mb-1">ACABADO</h3>
                  <p>
                    Es el tipo especial de proceso de teñido. Los hilos se tiñen
                    en forma de paquete o formulario madeja por proceso de
                    teñido de los hilos que se diferencia del teñido tejido y
                    tejer ligeramente.
                  </p>
                </div>
              </div>
            </div>
            <div className="full-row flex space-between align-center mobile-column">
              <div className="col5 flex mobile-mb2">
                <div>
                  <p className="super-big">5</p>
                </div>
                <div>
                  <h3 className="mb-1">Empaquetado </h3>
                  <p>
                    Se define como el cambio de formato del hilado, contenido en
                    canillas, conos o madejas, a formatos llamados bobinas para
                    una mejor manipulación y realizar adecuadamente los procesos
                    posteriores del hilo.
                  </p>
                </div>
              </div>
              <div className="col45">
                <img
                  className="full-row"
                  src="/images/produccion/tela5.png"
                  alt="Empaquetado"
                />
                <svg
                  className="point left"
                  width="317"
                  height="22"
                  viewBox="0 0 317 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M26 11L317 11" stroke="#0B0B08" stroke-width="4" />
                  <rect width="95" height="22" fill="#D5DF2B" />
                </svg>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-4">
          <YellowBar />
        </section>
      </div>
    </div>
  );
};

export default Produccion;
