import React from "react";
import "../styles/pages-style/catalogo.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css";
import { Navigation, Pagination } from "swiper";
import TitleBlack from "../components/TitleBlack";
import YellowBar from "../components/YellowBar";
import { NavLink, useLocation } from "react-router-dom";

const Catalogo = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  return (
    <div id="catalogoPage" className="father">
      <div className="max-w">
        <section id="hero-catalogo">
          <TitleBlack titulo="Catálogo" />
          <div className="normal-pad">
            <h2 className="mb-2">Tejido de punto </h2>
            <h3 className="white light">
              Conoce todos los tipos de telas que puedes encontrar en Kansatex
            </h3>
          </div>
        </section>
        <section className="back-gray" id="catalogo-slider-wrapper">
          <Swiper
            breakpoints={{
              640: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 2,
              },
              1200: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={25}
            centeredSlides={false}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            nextEl={"arrow-right"}
            prevEl={"arrow-left"}
            modules={[Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card">
                <div className="flex align-center">
                  <h2>1.</h2>
                  <h3>Chifon</h3>
                </div>
                <p className="mb-2 justify">
                  Se denomina{" "}
                  <b>
                    chifón a una tela muy liviana, de confección plana
                    equilibrada y elaborada utilizando hilos crepé hilado.
                  </b>{" "}
                  El chifón se fabrica a partir de{" "}
                  <b>algodón, seda o fibras sintéticas.</b> El chifón se puede
                  teñir con cualquier tono. Vista bajo una lupa la tela es
                  similar a una red fina o malla. El chifón utilizado para{" "}
                  <b>confeccionar vestimentas para la noche,</b> para darle una
                  apariencia elegante y etérea a la capa.
                </p>
                <img src="images/catalogo/catalogo1.jpg" alt="Ejemplo Chifón" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="flex align-center">
                  <h2>2.</h2>
                  <h3>Pique</h3>
                </div>
                <p className="mb-2 justify">
                  El piqué, punto imperial o marsella{" "}
                  <b>
                    es untejido fraccionado de doce en doce hilos, normalmente
                    usado con hilo de algodón,
                  </b>{" "}
                  que se caracteriza porque la mitad de los hilos levantados de
                  cada sección cambia alternativamente en cada pasada. El
                  algodón de Twilled y el algodón cordado son familiares
                  cercanos. Actualmente, el piqué se emplea{" "}
                  <b>
                    sobre todo para corbatas. Otro uso para él es en playeras de
                    polo y vestidos de mujer
                  </b>{" "}
                  , principalmente.
                </p>
                <img src="images/catalogo/catalogo2.jpg" alt="Ejemplo Pique" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="flex align-center">
                  <h2>3.</h2>
                  <h3>Interlock</h3>
                </div>
                <p className="mb-2 justify">
                  Se denomina{" "}
                  <b>
                    chifón a una tela muy liviana, de confección plana
                    equilibrada y elaborada utilizando hilos crepé hilado.
                  </b>{" "}
                  El chifón se fabrica a partir de{" "}
                  <b>algodón, seda o fibras sintéticas.</b> El chifón se puede
                  teñir con cualquier tono. Vista bajo una lupa la tela es
                  similar a una red fina o malla. El chifón utilizado para{" "}
                  <b>confeccionar vestimentas para la noche,</b> para darle una
                  apariencia elegante y etérea a la capa.
                </p>
                <img
                  src="images/catalogo/catalogo3.jpg"
                  alt="Ejemplo Interlock"
                />
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="yellow-line"></div>
        </section>
        <section>
          <YellowBar />
          <div className="flex align-center justify-center column normal-pad center">
            <h3 className="light mb-2">
              Contactanos para una atención personalizada
            </h3>
            <NavLink className="btn" to="/contacto">
              Contáctanos
            </NavLink>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Catalogo;
