import React from "react";
import TitleBlack from "../components/TitleBlack";
import "../styles/pages-style/puntos.css";
import YellowBar from "../components/YellowBar";

const PuntosDeVenta = () => {
  return (
    <div id="puntosdeventaPage">
      <div className="father">
        <div className="max-w">
          <section className="normal-pad">
            <TitleBlack titulo="Puntos de Venta" />
          </section>
          <section>
            <div className="mb-4 yellow-bar normal-pad">
              <h3>
                Conoce nuestros distintos puntos de venta y ponte en contacto
                con nosotros.
              </h3>
            </div>
          </section>
          <section className="puntos-de-venta-wrapper normal-pad">
            <div className="flex align-center mobile-column mb-8">
              <div className="left-block mobile-mb2 map">
                <div className="map-background">
                  <div className="color-bg"></div>{" "}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1881.2313465325396!2d-99.12807874191581!3d19.43560804672067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1f9353bcfb287%3A0x10b9c97fcd768478!2sMercado%20de%20Mixcalco!5e0!3m2!1ses-419!2smx!4v1662733619392!5m2!1ses-419!2smx"
                    width="100%"
                    height="100%"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
              <div className="right-block">
                <div className="flex-ro">
                  <img
                    src="/images/icons/location.svg"
                    alt="icono de locación"
                  />
                  <p>
                    Calle Guatemala # 131 local 3 col centro Frente al mercado
                    mixcalco.
                  </p>
                </div>
                <div className="flex-ro">
                  <img
                    src="/images/icons/wha-black.svg"
                    alt="icono de locación"
                  />
                  <a href="tel:5521209437">Tel. 5521209437</a>
                </div>
                <div className="flex-ro">
                  <img
                    src="/images/icons/wha-green.svg"
                    alt="icono de locación"
                  />
                  <a href="tel:5549351045">Cel. 5549351045</a>
                </div>
              </div>
            </div>
            <div className="flex align-center mobile-column mb-8 invertido">
              <div className="left-block">
                <div className="flex-ro">
                  <img
                    src="/images/icons/location.svg"
                    alt="icono de locación"
                  />
                  <p>
                    Av. Xochimilco local 5c. San Pedro chiconcuac C.P. 56270
                  </p>
                </div>
                <div className="flex-ro">
                  <img
                    src="/images/icons/wha-black.svg"
                    alt="icono de locación"
                  />
                  <a href="tel:5959217762">Tel. 5959217762</a>
                </div>
                <div className="flex-ro">
                  <img
                    src="/images/icons/wha-green.svg"
                    alt="icono de locación"
                  />
                  <a href="tel:5540284395">Cel. 5540284395</a>
                </div>
              </div>
              <div className="right-block mobile-mb2 map">
                <div className="map-background">
                  <div className="color-bg"></div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3759.788260909923!2d-98.90012278510542!3d19.55070198681732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e89a1ce6689b%3A0xe79f87883be996ea!2sBulevar%20Xochimilco%2C%20San%20Pedro%2C%20Chiconcuac%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1662734063638!5m2!1ses-419!2smx"
                    width="100%"
                    height="100%"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
            <div className="flex align-center mobile-column mb-8">
              <div className="left-block mobile-mb2 map">
                <div className="map-background">
                  <div className="color-bg"></div>{" "}
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7519.45442505494!2d-98.90247892531085!3d19.553321597263444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1e8973cf7484d%3A0x6f7089d9b7acd340!2sSan%20Pedro%2C%2056270%20Chiconcuac%2C%20M%C3%A9x.!5e0!3m2!1ses-419!2smx!4v1662733756236!5m2!1ses-419!2smx"
                    width="100%"
                    height="100%"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
              <div className="right-block">
                <div className="flex-ro">
                  <img
                    src="/images/icons/location.svg"
                    alt="icono de locación"
                  />
                  <p>
                    Juan León #12 Col San Pedro Chiconcuac Estado de México C.P.
                    55270
                  </p>
                </div>
                <div className="flex-ro">
                  <img
                    src="/images/icons/wha-black.svg"
                    alt="icono de locación"
                  />
                  <a href="tel:5959532012">Tel. 5959532012</a>
                </div>
                <div className="flex-ro">
                  <img
                    src="/images/icons/wha-green.svg"
                    alt="icono de locación"
                  />
                  <a href="tel:5951147149">Cel. 5951147149</a>
                </div>
              </div>
            </div>
            <div className="flex align-center mobile-column mb-8 invertido">
              <div className="left-block">
                <div className="flex-ro">
                  <img
                    src="/images/icons/location.svg"
                    alt="icono de locación"
                  />
                  <p>
                    Primera sección Sagrado Corazón de Jesús 90870 MAZATECOCHCO,
                    TLAXCALA.
                  </p>
                </div>
                <div className="flex-ro inline">
                  <img
                    src="/images/icons/wha-black.svg"
                    alt="icono de locación"
                  />
                  <p className="mr-10">Telefonos: </p>
                  <a href="tel:2222793855">2222793855</a>
                  <p className="mrl-10"> / </p>
                  <a href="tel:5630477191">5630477191</a>
                </div>
              </div>
              <div className="right-block mobile-mb2 map">
                <div className="map-background">
                  <div className="color-bg"></div>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.4395587180534!2d-98.19331458511131!3d19.175994437030596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cfc33cc004af15%3A0x51d2faa48056f03a!2sPrimera%20Secc%20Sagrado%20Coraz%C3%B3n%20de%20Jes%C3%BAs%2C%2090870%20Mazatecochco%2C%20Tlax.!5e0!3m2!1ses-419!2smx!4v1662733915855!5m2!1ses-419!2smx"
                    width="100%"
                    height="100%"
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PuntosDeVenta;
